import { useEffect, useState } from "react";
import Uppy, { Meta, UppyFile } from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import { useRouter } from "next/router";
import { useUploadPdfMutation } from "@/api/api";

interface CustomUppyFile
    extends UppyFile<Record<string, unknown>, Record<string, unknown>> {
    xhrUpload?: {
        endpoint: string;
        method?: string;
        formData?: boolean;
        fieldName?: string;
    };
}

const useUppyPdfUpload = () => {
    const [uploadPdf] = useUploadPdfMutation();
    const router = useRouter();

    const initializeUppy = () => {
        const uppyInstance = new Uppy({
            restrictions: {
                maxFileSize: null,
                maxNumberOfFiles: null,
                minNumberOfFiles: 1,
                allowedFileTypes: [".pdf"],
            },
            autoProceed: false,
        });

        let files: Record<string, UppyFile<Meta, Record<string, never>>> = {};

        uppyInstance.use(XHRUpload, {
            method: "PUT",
            formData: false,
            fieldName: "files",
            endpoint: "dummy_endpoint",
            onAfterResponse: (xhr, retryCount) => {
                const file = files[xhr.responseURL];
                if (file) {
                    uppyInstance.emit('upload-success', file, {
                        status: 200,
                        body: {},
                    });
                }
            },
        });

        uppyInstance.on("file-added", async (addedFile) => {
            const file = addedFile;
            try {
                if (!file.name) {
                    throw new Error("File name is required");
                }
                const mutationResponse = await uploadPdf({
                    file_path: file.name,
                });
                const uploadUrl = mutationResponse.data?.upload_url;
                if (uploadUrl) {
                    files[uploadUrl] = file;
                    uppyInstance.setFileState(file.id, {
                        xhrUpload: { endpoint: uploadUrl },
                    });
                } else {
                    throw new Error(`${file.name} has already been added to Driver`);
                }
            } catch (e) {
                if (e instanceof Error) {
                    uppyInstance.info(e.message, "error", 5000);
                    uppyInstance.removeFile(file.id);
                }
            }
        });

        return uppyInstance;
    };

    const [uppy] = useState<Uppy>(initializeUppy);

    useEffect(() => {
        return () => {
            uppy.destroy();
        };
    }, [uppy]);

    return uppy;
};

export default useUppyPdfUpload;